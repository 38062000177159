<template>
    <div class="row" v-if="v">
        <div class="col-12">
            <mercur-input data-test="locationFinanceChamberCommerceRegistrationNumber"
                v-model="v.financeSettings.chamberCommerceRegistrationNumber.$model"
                required
                :class="{'form-invalid': v.financeSettings.chamberCommerceRegistrationNumber.$error}">
                Chamber of Commerce Reg. Number
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.chamberCommerceRegistrationNumber.required">Required</span>
                </template>
            </mercur-input>
        </div>

        <div class="col-12" >
            <mercur-select data-test="locationFinanceCurrency" v-model="v.financeSettings.currency.$model" v-on:keypress.enter.prevent>
                <template slot="label">Currency</template>
                <option
                    v-for="(currency, key) in availableCurrencies"
                    :key="key"
                    :value="currency.code">
                    {{currency.code}}
                </option>
                <template slot="note">
                    <span class="form-error" v-if="v.financeSettings.currency.$error && !v.financeSettings.currency.required">Currency is required</span>
                </template>
            </mercur-select>
        </div>

        <div class="col-12">
            <mercur-input data-test="locationFinanacePaymentTermsValue"
                v-model.number="v.financeSettings.paymentTermsValue.$model"
                type="number"
                required
                :class="{'form-invalid': v.financeSettings.paymentTermsValue.$error}">
                Payment terms
                <div slot="suffix">days</div>
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.paymentTermsValue.required">Required</span>
                </template>
            </mercur-input>
            <input type="hidden" name="location.financeSettings.paymentTermsUnit" :value="'DAYS'" />
        </div>

        <div class="col-4">
            <mercur-input data-test="locationFinanaceInvoiceFrequencyValue"
                v-model.number="v.financeSettings.invoiceFrequencyValue.$model"
                type="number"
                required
                :class="{'form-invalid': v.financeSettings.invoiceFrequencyValue.$error}">
                Invoice frequency
                <div slot="suffix">#</div>
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.invoiceFrequencyValue.required">Required</span>
                </template>
            </mercur-input>
        </div>
        <div class="col-8">
            <mercur-select data-test="locationFinanaceInvoiceFrequencyUnit"
                v-model="v.financeSettings.invoiceFrequencyUnit.$model" v-on:keypress.enter.prevent
                required
                :class="{'form-invalid': v.financeSettings.invoiceFrequencyUnit.$error}">
                <template slot="label">days/weeks/months</template>
                <option value="DAYS">days</option>
                <option value="WEEKS">weeks</option>
                <option value="MONTHS">month</option>
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.invoiceFrequencyUnit.required">Required</span>
                </template>
            </mercur-select>
        </div>

        <div class="col-12">
            <mercur-input data-test="locationFinanaceBankName"
                v-model="v.financeSettings.bankName.$model"
                required
                :class="{'form-invalid': v.financeSettings.bankName.$error}">
                Bank name
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.bankName.required">Required</span>
                </template>
            </mercur-input>
        </div>
        <div class="col-12">
            <mercur-input data-test="locationFinanaceBankAccountNumber"
                v-model="v.financeSettings.bankAccountNumber.$model"
                required
                :class="{'form-invalid': v.financeSettings.bankAccountNumber.$error}">
                Bank account number
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.bankAccountNumber.required">Required</span>
                </template>
            </mercur-input>
        </div>
        <div class="col-12">
            <mercur-input data-test="locationFinanaceBankSwiftCode"
                v-model="v.financeSettings.bankSwiftCode.$model"
                required
                :class="{'form-invalid': v.financeSettings.bankSwiftCode.$error}">
                Swift code
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.bankSwiftCode.required">Required</span>
                </template>
            </mercur-input>
        </div>

        <div class="col-12">
            <mercur-input data-test="locationFinanaceVatNumber"
                v-model="v.financeSettings.vatNumber.$model"
                required
                :class="{'form-invalid': v.financeSettings.vatNumber.$error}">
                VAT number
                <template slot="note">
                    <span class="form-error" v-if="!v.financeSettings.vatNumber.required">Required</span>
                </template>
            </mercur-input>
        </div>
    </div>
</template>

<script>
import currencyToSymbolMap from 'currency-symbol-map/map'

export default {
    name: 'LocationFinanceFields',
    props: {
        location: Object,
        v: {},
    },
    data () {
        return {
            availableCurrencies: Object.entries(currencyToSymbolMap).map(([code, sign]) => {
                return {
                    code,
                    sign,
                }
            }),
        }
    },
    created () {
        this.location.financeSettings.paymentTermsUnit = 'DAYS'
    },
}
</script>
